<script lang="ts" setup></script>

<template>
  <div class="line"></div>
</template>

<style lang="scss" scoped>
.line {
  position: relative;
  width: 100%;
  height: toRem(48);
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: toRem(1);
    background-color: $color-gray-line;
  }
}
</style>
