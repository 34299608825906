<script lang="ts" setup>
import { useUserStore } from '@/stores'
const userStore = useUserStore()
interface List {
  title: string
  icon: string
  link?: any
}

const lists: Ref<List[]> = ref([
  {
    title: userStore.token === null ? '會員註冊/登入' : '會員中心',
    icon: 'icon-member',
    link: userStore.token === null ? import.meta.env.VITE_SSO_URL.replace("{:redirect_url}", import.meta.env.VITE_DOMAIN).replace("{:banner_id}", 1) : '/member'
  },
  {
    title: '維修/贈品進度查詢',
    icon: 'icon-service',
    link: '/member/service'
  }
])
</script>

<template>
  <ul class="other">
    <li v-for="link in lists">
      <nuxt-link :to="link.link" class="other__link">
        <svg-icon :name="link.icon" class="other__link__icon" />
        <span class="other__link__title">{{ link.title }}</span>
      </nuxt-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.other {
  &__link {
    display: flex;
    align-items: center;
    padding-top: toRem(6);
    padding-bottom: toRem(6);

    &__icon {
      width: toRem(20);
      height: toRem(20);
      margin-right: toRem(10);
    }

    &__title {
      font-weight: 700;
    }
  }
}
</style>
