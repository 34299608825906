<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
const globalStore = useGlobalStore()
import { type MobileBottom } from '@/utils/types/MobileBottom'

const menuLists: Ref<MobileBottom[]> = ref([
  {
    title: '找生活',
    isOpen: false,
    iconName: 'icon-Menu1',
    child: [
      {
        title: 'hence, 雜誌',
        link: '/hence'
      },
      {
        title: 'zonezone 概念店',
        link: '/zonezone'
      }
    ]
  },
  {
    title: '找好物',
    isOpen: false,
    iconName: 'icon-Menu2',
    child: [
      {
        title: '線上購物',
        link: 'https://shop.hengstyle.com/'
      },
      {
        title: '選品服務',
        link: '/services/select'
      }
    ]
  },
  {
    title: '找服務',
    link: '/services',
    isOpen: false,
    iconName: 'icon-Menu3'
  }
])

const isMaskOpen: Ref<boolean> = ref(false)

const handleChildMenuToggle = (index: any) => {
  isMaskOpen.value = false

  menuLists.value.forEach((item, itemIndex) => {
    if (itemIndex !== index) {
      item.isOpen = false
    } else {
      item.isOpen = !item.isOpen
      if (item.isOpen) {
        isMaskOpen.value = true
      }
    }
  })
}

const handleCloseChildMenu = () => {
  isMaskOpen.value = false
  menuLists.value.forEach((item) => {
    item.isOpen = false
  })
}

const isMenuClose: Ref<boolean> = ref(true) // menu是否關閉
const currentTop: Ref<number> = ref(0) // 當前top
const prevTop: Ref<number> = ref(0) // 上一個top值
//scroll 事件
const handleScroll = () => {
  currentTop.value = window.scrollY

  if (currentTop.value > prevTop.value || currentTop.value < 50) {
    isMenuClose.value = true
    globalStore.isScrollMenuClose = true
  } else {
    isMenuClose.value = false
    globalStore.isScrollMenuClose = false
  }

  prevTop.value = window.scrollY
}

onMounted(() => {
  document.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  document.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div class="mobile-bottom-mask" :class="{ isOpen: isMaskOpen }" @click="handleCloseChildMenu"></div>
  <div class="mobile-bottom" :class="{ isClose: isMenuClose }">
    <ul class="menu">
      <li v-for="(item, index) in menuLists" :key="index">
        <div class="menu__item">
          <template v-if="item.child">
            <div class="menu__title cursor-pointer" :class="{ isOpen: item.isOpen }"
              @click="handleChildMenuToggle(index)">
              <div class="menu__title-icon">
                <svg-icon :name="item.isOpen ? 'icon-Replay' : item.iconName" />
              </div>
              {{ item.title }}
            </div>
            <transition>
              <div class="menu__child" v-show="item.isOpen">
                <div class="menu__child__inner">
                  <nuxt-link v-for="(child, childIndex) in item.child" :key="childIndex" :to="child.link"
                    :title="child.title" class="menu__child__title">
                    {{ child.title }}
                  </nuxt-link>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
            <nuxt-link class="menu__title" :to="item.link" :title="item.title">
              <div class="menu__title-icon">
                <svg-icon :name="item.isOpen ? 'icon-Replay' : item.iconName" />
              </div>
              {{ item.title }}
            </nuxt-link>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.mobile-bottom-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 119;
  display: none;

  &.isOpen {
    display: block;
  }
}

.mobile-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 120;
  width: 100%;
  height: toRem(50);
  background-color: $color-white-2;
  transition: all 0.5s ease-in-out;

  &.isClose {
    transform: translateY(50px);
  }
}

.menu {
  display: flex;
  justify-content: center;
  position: relative;

  &__item {
    padding-left: toRem(60);
    padding-right: toRem(60);

    @include max-media(500) {
      padding-left: toRem(30);
      padding-right: toRem(30);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: toRem(50);
    font-size: toRem(14);

    &-icon {
      width: toRem(20);
      height: toRem(20);
      margin-bottom: toRem(2);
    }
  }

  &__child {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    padding-top: toRem(5);
    padding-bottom: toRem(5);
    background-color: $color-white-2;

    &__title {
      display: block;
      text-align: center;
      font-size: toRem(14);
      padding-top: toRem(5);
      padding-bottom: toRem(5);
    }
  }
}

.mobile-bottom-mask,
.mobile-bottom {
  @include min-media(991) {
    display: none !important;
  }
}
</style>
