<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
import { storeToRefs } from 'pinia'
import MobileMenu from '@/components/Mobile/MobileMenu.vue'
import MobileMenuOther from '@/components/Mobile/MobileMenuOther.vue'

const route = useRoute()

const globalStore = useGlobalStore()
const { WEB_TITLE, isMobileMenuOpen } = storeToRefs(globalStore)

const keyword = ref('')
const handleSearch = async () => {
  await navigateTo(`/search?q=${keyword.value}`)
}

// 監聽路由切換關閉選單
watch(
  () => route.path,
  () => {
    isMobileMenuOpen.value = false
  }
)
</script>

<template>
  <section class="mobile-menu" :class="{ isShow: isMobileMenuOpen }">
    <div class="mobile-menu__header">
      <nuxt-link to="#" class="mobile-menu__logo"><img src="@/assets/images/logo.svg" :alt="WEB_TITLE" /></nuxt-link>
      <button class="mobile-menu__button" @click="isMobileMenuOpen = false" title="關閉選單按鈕"></button>
    </div>
    <div class="mobile-menu__content">
      <Searchbar class="mobile-menu__search mobile-menu__animation mobile-menu__animation-1" v-model="keyword"
        :handle-event="handleSearch" />
      <MobileMenuOther class="mobile-menu__animation mobile-menu__animation-2" />
      <Line class="mobile-menu__animation mobile-menu__animation-3" />
      <MobileMenu class="mobile-menu__animation mobile-menu__animation-4" />
      <Line class="mobile-menu__animation mobile-menu__animation-5" />
      <div class="mobile-menu__item mobile-menu__animation mobile-menu__animation-6">
        <nuxt-link to="/contact">
          <div class="mobile-menu__title">聯絡我們</div>
        </nuxt-link>
        <Social />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: $color-white-1;
  overflow: hidden;
  transform: translateY(-100%);
  pointer-events: none;
  visibility: visible;
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);

  &.isShow {
    transform: translateY(0);
    pointer-events: auto;
    visibility: visible;
  }

  &__header,
  &__content {
    padding-left: toRem(20);
    padding-right: toRem(20);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: toRem(68);
  }

  &__logo {
    display: block;
    width: toRem(111);

    img {
      display: block;
      width: 100%;
    }
  }

  &__button {
    position: relative;
    width: toRem(28);
    height: toRem(28);
    border: none;
    background: none;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: toRem(23);
      height: toRem(2);
      background-color: $color-black-1;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__content {
    padding-top: toRem(20);
    padding-bottom: toRem(20);
    height: calc(100vh - toRem(68));
    overflow-y: auto;
  }

  &__title {
    margin-bottom: toRem(10);
  }

  &__search {
    margin-bottom: toRem(25);
  }

  &__animation {
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.5s ease-in-out;
  }

  &.isShow {
    .mobile-menu {
      &__animation {
        transform: translateY(0);
        opacity: 1;

        &-1 {
          transition-delay: 0.1s;
        }

        &-2 {
          transition-delay: 0.2s;
        }

        &-3 {
          transition-delay: 0.3s;
        }

        &-4 {
          transition-delay: 0.4s;
        }

        &-5 {
          transition-delay: 0.5s;
        }

        &-6 {
          transition-delay: 0.6s;
        }
      }
    }
  }
}
</style>
